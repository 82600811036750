/* App.css */
.app-container {
  font-family: 'JetBrains Mono', monospace;
  text-align: center;
}

.svg-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-image: url('./background.svg');
  background-size: cover;
  opacity: 0.3; /* Adjust opacity as needed */
}

.header-svg-text {
  display: inline-block;
  height: 4em; /* Match the height of h1 */
  width: auto;
  margin-bottom: 2.5em; /* Adjusted for dynamic spacing */

}





body {
  font-family: 'JetBrains Mono', monospace;
  background-color: #F8F6F2;
  margin: 0;
  padding: 0;
  /* Define the size of the grid squares */
  /* background-size: 70px 70px; */
  /* Create a horizontal and vertical line pattern */
  /* background-image: 
    linear-gradient(to right, rgb(235, 235, 235) 1px, transparent 1px),
    linear-gradient(to bottom, rgb(235, 235, 235) 1px, transparent 1px); */
}

.header {
  text-align: center;
  margin-top: 5em; /* Adjusted for dynamic spacing */
}

h1 {
  font-size: 4em;
  margin-bottom: 0em;
}
h3 {
  font-size: 1em;
  margin-top: 0em;
  font-weight: lighter;
}



/* Styles for the question input area */

.question-input {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.question-input textarea {
  width: 100%; /* Responsive width */
  min-height: 100px; /* Adjustable height */
  margin: 20px auto; /* Centering */
  display: block;
  padding: 20px;
  border-radius: 5px;
  border: 1px solid #424242;
  resize: none; /* Prevent resizing */
  background-color: #fff;
  box-sizing: border-box; /* Padding included in width and height */
}

.question-input textarea::placeholder {
  color: #808080;
}


.button-group button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: #222222; /* Primary button color */
  color: #ffffff;
  cursor: pointer;
  font-size: 1rem;
  font-family: 'JetBrains Mono', monospace;
  
}

.button-group button:hover {
  background-color: #000000; /* Darken button on hover */
}



/* Styles for LaTeX display */
.latex-display {
  max-width: 80%;
  margin: 20px auto; /* This will center the element horizontally */
  padding: 20px;
  background: #faf9f8;
  border: 1px solid #ddd;
  border-radius: 5px;
  display: flex;
  justify-content: center; /* Center the LaTeX content horizontally */
  align-items: center; /* Center the LaTeX content vertically */
  text-align: center;
  word-break: break-word;
  overflow-wrap: break-word;
  position: relative; /* Allow absolute positioning */
}

/* Parent container for solution steps */
.solution-container {
  display: flex;
  flex-direction: column; /* Stack elements vertically */
  align-items: center; /* Center elements horizontally */
  width: 100%; /* Take full width of the container */
}

.step-number {
  background-color: #e4e3e3;
  font-size: 15px;
  color: #000000;
  position: absolute; /* Position it absolutely */
  top: 10px; /* Align to the top */
  left: 10px; /* Align to the left */
  padding: 5px; /* Add padding */
  border-radius: 5px; /* Rounded corners */
}

.step-number-task {
  background-color: #e4e3e3;
  font-size: 15px;
  color: #000000;
  position: absolute; /* Position it absolutely */
  top: 10px; /* Align to the top */
  left: 10px; /* Align to the left */
  padding: 5px; /* Add padding */
  border-radius: 5px; /* Rounded corners */
}

.latex-text {
  padding: 10px;
}

.latex-text-task {
  font-weight: bold;
}

.latex-display-question {
  width: 80%;
  margin: 20px auto;
  padding: 10px;
  background: #ffffff;
  border: 1px solid #ddd;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  word-break: break-word;
  overflow-wrap: break-word;
}


.loader {
  border: 6px solid #f3f3f3; /* Light grey */
  border-top: 6px solid #000000; /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
  margin: 20px auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.thinking-text {
  font-size: 20px;
  color: #000000;
  text-align: center;
}

.blinking-cursor {
  font-weight: 100;
  font-size: 20px;
  color: #030303;
  -webkit-animation: 1s blink step-end infinite;
  animation: 1s blink step-end infinite;
}

@keyframes blink {
  from, to { color: transparent }
  50% { color: #000000 }
}

@-webkit-keyframes blink {
  from, to { color: transparent }
  50% { color: #000000 }
}

/* Add the rest of your styles above this line */

sentence-block {
  width: 60%;
  border: 1px solid #ddd;
  padding: 10px;
  margin-bottom: 10px;
  margin-left: auto; /* Center align */
  margin-right: auto; /* Center align */
  border-radius: 5px;
  background-color: #c9c9c9;
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1);
}

/* Hide the default file input */
.file-input {
  opacity: 0;
  width: 0.1px;
  height: 0.1px;
  position: absolute;
  z-index: -1;
}

/* Custom button styling */
.upload-button {
  background-color: #4CAF50; /* Green background */
  color: white; /* White text */
  padding: 10px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

/* Styling for the label which will be our new 'button' */
.upload-button-label {
  cursor: pointer;
  display: inline-block;
  padding: 10px 20px;
  background-color: #d4d4d4;
  border-radius: 5px;
  
  color: #000000;
  text-align: center;
  font-size: 16px;
  transition: all 0.3s;
}

/* Change color on hover */
.upload-button-label:hover {
  background-color: #d1d1d1;
}

/* Style for the file name display */
.file-name-display {
  margin-top: 5px;
  font-size: 14px;
  color: #666;
}

.button-group {
  display: flex;
  justify-content: center; /* Centers the buttons */
  align-items: center;
  gap: 10px; /* Adjust the space between buttons */
  margin-top: 10px; /* Space from the textarea above */
}

/* Make sure the upload label and solve button are properly aligned */
.upload-button-label, .solve-button {
  display: inline-block; /* Treat them as inline elements with block capabilities */
  padding: 10px 20px; /* Add padding inside the buttons */
  margin: 0; /* Remove any default margins */
  vertical-align: middle; /* Align vertically if they are not of the same height */
  /* Continue with the rest of your styles for these elements */
}

.app-footer {
  text-align: center;
  margin-top: 20px;
  padding: 10px;
  background-color: #fcfcfc00; /* Light grey background */
  color: #333; /* Dark text color for contrast */
}

.app-footer a {
  color: #007bff; /* Blue color for links */
  text-decoration: none; /* No underline */
}

.app-footer a:hover {
  text-decoration: underline; /* Underline on hover for better visibility */
}




/* Parent container styling */
.app-switch {
  display: flex;
  justify-content: center; /* Centers all child elements horizontally */
  align-items: center; /* Aligns children vertically */
  width: 100%; /* Takes full width */
  flex-direction: column; /* Stacks children vertically */
  
}

/* Mode switch container styling */
.mode-switch {
  display: flex;
  justify-content: space-between; /* Ensures space is distributed evenly between items */
  align-items: center; /* Vertically aligns the items */
  width: 40%; /* Optional: adjust as needed for your design */
}

/* Mode option button styling */
.mode-option {
  flex: 1; /* Makes each flex item grow to take up equal space */
  text-align: center; /* Centers text horizontally */
  padding: 10px;
  cursor: pointer; /* Indicates that the element is clickable */
  background-color: rgb(231, 230, 230); /* Visibility */
  border: 1px solid black; /* Visibility */
  margin: 0; /* No margin between items */
  border-radius: 8px; /* Rounded corners */
  transition: background-color 0.3s, color 0.3s, transform 0.2s; /* Smooth transitions */
}

/* Hover effect for mode options */
.mode-option:hover {
  background-color: #dbdbdb; /* Slightly darker on hover */
  transform: scale(1.05); /* Slightly larger on hover */
}

/* Styling for the active mode */
.active-mode {
  background-color: black;
  color: white;
  border-color: black;
  border-radius: 8px; /* Rounded corners for the active mode */
  font-weight: bold;
}

/* Active mode hover effect */
.active-mode:hover {
  background-color: black; /* No change on hover */
  color: white; /* No change on hover */
  transform: scale(1.05); /* No change on hover */
}

.questions-left {
  position: relative;
  margin-top: 20px;
  margin-left: 20px;
  background-color: white;
  padding: 10px;
  border-radius: 5px;
  display: inline-block;
  border: 1px solid #000000; /* Combined border properties for simplicity */
}
.popup {
  visibility: hidden; /* Hide the popup by default */
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear;
  width: 200px; /* Increased width for better readability */
  background-color: #333; /* Darker background for better contrast */
  color: #fff; /* White text for better readability */
  text-align: center;
  border-radius: 8px; /* Slightly larger border radius for smoother edges */
  padding: 10px; /* More padding for better spacing */
  position: absolute;
  z-index: 1;
  top: 100%; /* Position the popup below the element */
  left: 50%;
  transform: translateX(-50%); /* Center the popup horizontally */
  box-shadow: 0px 8px 15px rgba(0,0,0,0.3); /* Stronger shadow for better visibility */
  font-size: 14px; /* Slightly larger font size */
  line-height: 1.5; /* Improved line spacing */
}

.popup.visible {
  visibility: visible; /* Show the popup */
  opacity: 1;
}

.popup a {
  color: #5865F2;; /* Red color for links */
  text-decoration: none; /* No underline for links */
  margin: 0 5px; /* Spacing between links */
  font-weight: bold; /* Bold font for better emphasis */
}

.popup a:hover {
  text-decoration: underline; /* Underline on hover for links */
}
.textbox {
  position: relative;
  width: 40%;
  
}

.image-loader {
  position: absolute;
  top: 30px; /* Positioned towards the top inside the container */
  left: 10px; /* Positioned towards the left inside the container */
  width: 40px; /* Small fixed width for a circular loader */
  height: 40px; /* Small fixed height for a circular loader */
  border: 5px solid #f3f3f3; /* Light grey border */
  border-top: 5px solid #000000; /* Blue border for the top to show progress */
  border-radius: 50%; /* Rounded borders to make it circular */
  animation: spin 2s linear infinite;
  z-index: 10;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}



.menu-button-container {
  position: fixed; /* Changed from absolute to fixed to stick to the viewport */
  top: 30px;
  right: 10px;
}

.menu-button {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: black; /* Ensuring the button is visible on dark background */
}

.menu-popup {
  position: absolute;
  top: 45px;
  right: 0;
  background: #000000;
  border: 1px solid #444;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  padding: 10px 0;
  min-width: 200px;
}

.menu-item {
  display: block;
  padding: 10px 20px;
  width: 100%;
  text-align: left;
  background: none;
  border: none;
  cursor: pointer;
  color: white;
  font-family: 'JetBrains Mono', monospace;
  font-weight: bold;
}

.menu-item:hover {
  background: #444;
}

.menu-item:focus {
  outline: none;
}

.no-underline {
  text-decoration: none;
}

.loading-container-splash{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  max-width: 80%; /* Ensures responsiveness */
  width: 500px; /* Set a maximum width for the dialog */
  text-align: center; /* Center-align text within the dialog */
}

.dialog-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dialog-content p {
  margin: 10px 0;
  font-size: 1.2rem; /* Slightly larger font size for readability */
  color: black; /* Dark text color for contrast */
  font-weight: bold; /* Bold text for emphasis */
}

.dialog-content a {
  margin: 10px 0;
  color: white; /* Blue color for links */
  text-decoration: none; /* No underline */
  font-size: 1rem; /* Ensure link text is readable */
  background-color: #000000;
  padding: 5px 10px;
}

.dialog-content a:hover {
  text-decoration: underline; /* Underline on hover for better visibility */
}

.dialog-content button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #000000; /* Green background */
  color: white; /* White text */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem; /* Ensure button text is readable */
  transition: background-color 0.3s; /* Smooth transition for hover effect */
}

.dialog-content button:hover {
  background-color: #333
}

.discord{
  position: absolute;
  top: 20px;
  right: 60px;
  background-color: #5865F2;
  border-radius: 5px;
  padding: 10px;
}

.discord:hover{
  background-color: #333333;
  transform: scale(1.05);
}

.discord-link{
  color: white;
  text-decoration: none;
  font-family: 'JetBrains Mono', monospace;
  font-weight: bold;
}

.instagram{
  position: absolute;
  top: 20px;
  right: 280px;
  background-color: #ff7f7f;
  border-radius: 5px;
  padding: 10px;
}

.instagram:hover{
  background-color: #333333;
  transform: scale(1.05);
}

.instagram-link{
  color: white;
  text-decoration: none;
  font-family: 'JetBrains Mono', monospace;
  font-weight: bold;
}

.email{
  position: absolute;
  top: 20px;
  right: 245px;
  background-color: #000000;
  border-radius: 5px;
  padding: 10px;
}

.email:hover{
  background-color: #333333;
  transform: scale(1.05);
}

.email-link{
  color: white;
  text-decoration: none;
  font-family: 'JetBrains Mono', monospace;
  font-weight: bold;
}

/* Tablet breakpoints */
@media (max-width: 768px) {
  .header {
    margin-top: 4em;
  }
  h1 {
    font-size: 3em;
  }
  h3 {
    font-size: 1.25em;
  }
  .latex-display, .latex-display-question {
    max-width: 90%;
  }
  .button-group {
    flex-direction: row;
    gap: 5px;
  }
  .mode-switch {
    width: 80%;
  }
  .sentence-block {
    width: 80%;
  }
  .questions-left {
    top: 15px;
    right: 15px;
    font-size: 0.9rem;
  }
  .popup {
    width: 140px;
  }
  .textbox {
    width: 70%;
  }
  .dialog {
    max-width: 90%;
    width: auto;
  }
  .dialog-content p, .dialog-content button {
    font-size: 1rem;
  }
  .menu-popup {
    min-width: 180px;
  }
  .mode-option {
    padding: 9px;
  }
  .discord {
    font-size: 1rem;
    padding: 5px 10px;
    top: 30px;
    right: 10px;
    display: inline-block;
  }
  .instagram {
    font-size: 1rem;
    padding: 5px 10px;
    top: 60px;
    right: 10px;
    display: inline-block;
  }
  .menu-button-container {
    top: 90px;
    right: 5px;
    
  }
}

/* Phone breakpoints */
@media (max-width: 600px) {
  .header {
    margin-top: 3em;
  }
  h1 {
    font-size: 2.5em;
  }
  h3 {
    font-size: 1.1em;
  }
  .latex-display, .latex-display-question {
    max-width: 95%;
  }
  .button-group {
    flex-direction: row;
    gap: 5px;
  }
  .mode-switch {
    width: 80%;
  }
  .sentence-block {
    width: 90%;
  }
  .questions-left {
    top: 10px;
    right: 10px;
    font-size: 0.8rem;
  }
  .popup {
    width: 120px;
  }
  .textbox {
    width: 80%;
  }
  .dialog {
    max-width: 95%;
    width: auto;
  }
  .dialog-content p, .dialog-content button {
    font-size: 0.9rem;
  }
  .menu-popup {
    min-width: 150px;
  }
  .mode-option {
    padding: 8px;
  }

  .discord {
    font-size: 0.9rem;
    padding: 5px 10px;
    top: 30px;
    right: 10px;
    display: inline-block;
  }
  .instagram {
    font-size: 0.9rem;
    padding: 5px 10px;
    top: 60px;
    right: 10px;
    display: inline-block;
  }
  .menu-button-container {
    top: 90px;
    right: 5px;
    
  }
  
}

/* Smaller phone breakpoints */
@media (max-width: 480px) {
  .header {
    margin-top: 2em;
  }
  h1 {
    font-size: 2em;
  }
  h3 {
    font-size: 1em;
  }
  .latex-display, .latex-display-question {
    max-width: 100%;
  }
  .button-group {
    flex-direction: row;
    gap: 3px;
  }
  .mode-switch {
    width: 90%;
  }
  .sentence-block {
    width: 95%;
  }
  .questions-left {
    top: 5px;
    left: 5px;
    font-size: 0.7rem;
  }
  .popup {
    width: 110px;
  }
  .textbox {
    width: 90%;
  }
  .dialog {
    max-width: 100%;
    width: auto;
  }
  .dialog-content p, .dialog-content button {
    font-size: 0.8rem;
  }
  .menu-popup {
    min-width: 140px;
  }
  .mode-option {
    padding: 7px;
  }
  
  .discord {
    font-size: 0.8rem;
    padding: 5px 10px;
    top: 30px;
    right: 10px;
    display: inline-block;
  }
  .instagram {
    font-size: 0.8rem;
    padding: 5px 10px;
    top: 60px;
    right: 10px;
    display: inline-block;
  }
  .menu-button-container {
    top: 90px;
    right: 5px;
   
  }
}

/* Very small phone breakpoints */
@media (max-width: 360px) {
  .header {
    margin-top: 1em;
  }
  h1 {
    font-size: 1.8em;
  }
  h3 {
    font-size: 0.9em;
  }
  .latex-display, .latex-display-question {
    max-width: 100%;
  }
  .button-group {
    flex-direction: row;
    gap: 2px;
  }
  .mode-switch {
    width: 100%;
  }
  .sentence-block {
    width: 100%;
  }
  .questions-left {
    top: 5px;
    left: 5px;
    font-size: 0.7rem;
  }
  .popup {
    width: 100px;
  }
  .textbox {
    width: 100%;
  }
  .dialog {
    max-width: 100%;
    width: auto;
  }
  .dialog-content p, .dialog-content button {
    font-size: 0.7rem;
  }
  .menu-popup {
    min-width: 130px;
  }
  .mode-option a{
    padding: 6px;
  }
  
  .discord {
    font-size: 0.7rem;
    padding: 4px 8px;
    top: 30px;
    right: 5px;
    display: inline-block;
  }

  .instagram {
    font-size: 0.7rem;
    padding: 4px 8px;
    top: 60px;
    right: 5px;
    display: inline-block;
  }

  .menu-button-container {
    top: 80px;
    right: 5px;
    
  }
}