/* Login.css */

h1 {
    font-size: 4em;
}

h3 {
    font-size: 1.5em;
}

.header-svg-text {
    display: inline-block;
    height: 4em; /* Match the height of h1 */
    width: auto;
    margin-bottom: 2.5em; /* Adjusted for dynamic spacing */
}

.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh; /* Full viewport height */
    justify-content: center;
    text-align: center;
    padding: 20px;
}

.login-header {
    margin-bottom: 20px; /* Space between header and button */
}

.login-button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    background-color: #000000;
    color: #ffffff;
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
    font-family: 'Merriweather', serif;
    transition: all 0.3s ease;
    overflow: hidden;
    position: relative;
    white-space: nowrap;
}

.login-button::after {
    content: '→';
    position: absolute;
    top: 45%;
    right: -25px;
    transform: translateY(-50%);
    transition: right 0.3s ease, transform 0.3s ease;
    font-size: 1.25rem;
    color: #ffffff;
}

.login-button:hover::after {
    right: 10px;
    transform: translateY(-50%) scale(1.15);
}

.login-button:hover {
    padding-right: 50px;
    transition: all 0.3s ease;
}

.background-video {
    width: 50%;
    height: auto;
    margin-top: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Optional shadow for styling */
}
