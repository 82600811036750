/* Styles for HistoryPage */

.history-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0px;
    position: relative;
    width: 100%;
}
  
.top-left-container {
    position: absolute;
    top: 20px; /* Adjust to your preference */
    left: 20px; /* Adjust to your preference */
}
  
.home-link {
    text-decoration: none;
    color: black; /* Change to desired color */
}
  
.question-history-title {
    margin-top: 50px;
    text-align: center;
    width: 100%;
}
  
.content-container {
    margin-top: 20px;
    text-align: center;
    width: 100%; /* Ensure the content container spans the full width */
    display: flex;
    flex-direction: column;
    align-items: center;
}
  
.back-button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    background-color: #000000;
    color: #ffffff;
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
    font-family: 'JetBrains Mono', monospace;
    transition: all 0.3s ease;
    overflow: hidden;
    position: relative;
    white-space: nowrap;
}
  
.back-button::after {
    content: '←';
    position: absolute;
    top: 45%;
    left: -25px;
    transform: translateY(-50%);
    transition: left 0.3s ease, transform 0.3s ease;
    font-size: 1.25rem;
    color: #ffffff;
}
  
.back-button:hover::after {
    left: 10px;
    transform: translateY(-50%) scale(1.15);
}
  
.back-button:hover {
    padding-left: 30px;
    transition: all 0.3s ease;
}
  
.steps-block{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%; /* Ensure the steps block spans the full width */

}

/* Styles for LaTeX display */
.latex-display {
    width: 100%; /* Make the answer block span full width */
    margin: 10px 0; /* Adjust margin for spacing */
    padding: 20px;
    background: #faf9f8;
    border: 1px solid #ddd;
    border-radius: 5px;
    display: flex;
    justify-content: center; /* Center the LaTeX content */
    align-items: center;
    text-align: center;
    word-break: break-word;
    overflow-wrap: break-word;
    position: relative; /* Allow absolute positioning */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
}
  
.step-number {
    background-color: #e4e3e3;
    font-size: 15px;
    color: #000000;
    position: absolute; /* Position it absolutely */
    top: 10px; /* Align to the top */
    left: 10px; /* Align to the left */
    padding: 5px; /* Add padding */
    border-radius: 5px; /* Rounded corners */
}
  
.latex-text {
    padding: 10px;
    width: 100%;
}
  
.latex-display-question {
    max-width: 100%; /* Make the question block span full width */
    margin: 10px 0; /* Adjust margin for spacing */
    padding: 10px;
    background: #ffffff;
    border: 1px solid #ddd;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    word-break: break-word;
    overflow-wrap: break-word;
}
  
.loader {
    border: 6px solid #f3f3f3; /* Light grey */
    border-top: 6px solid #000000; /* Blue */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
    margin: 20px auto;
}
  
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
  
.thinking-text {
    font-size: 20px;
    color: #000000;
    text-align: center;
}
  
.blinking-cursor {
    font-weight: 100;
    font-size: 20px;
    color: #030303;
    -webkit-animation: 1s blink step-end infinite;
    animation: 1s blink step-end infinite;
}
  
@keyframes blink {
    from, to { color: transparent }
    50% { color: #000000 }
}
  
@-webkit-keyframes blink {
    from, to { color: transparent }
    50% { color: #000000 }
}
  
/* Additional styles */
.history-entry {
    border: 1px solid #ddd;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 5px;
    background-color: #f9f9f9;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
    width: 100%; /* Make the history entry block span full width */
    max-width: 90%; /* Limit the width for better readability */
    margin-left: auto; /* Center align */
    margin-right: auto; /* Center align */
}
  
strong {
    display: block;
}
  
.history-list {
    padding: 0;
    list-style-type: none;
    display: flex;
    flex-direction: column-reverse; /* Reverse the order */
    width: 100%; /* Make the list span full width */
}
  
ol {
    padding-left: 0; /* Remove padding for ordered list */
    list-style-type: none; /* Remove ordered list numbering */
}